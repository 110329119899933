<template>
  <div>
    <section class="container">
        <div class="grid">
            <div v-for="(item, index) in contents" :key="index">
                <categoryCard
                :content="item"
                :id="index"
                :cardType="fetchCardType(item)"
                @selection="lightUp">
                </categoryCard>
            </div>
        </div>
    </section>
    <section v-if="detailContent">
        <detailCard
            :content="detailContent"
            :closePopup="() => closeDetailCard()"
        ></detailCard>
    </section>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
    name: "grid",
    props: {
        contents: {
            type: Array
        },
        section: {
            type: Object
        }
    },
    data() {
        return {
            detailContent: null
        }
    },
    created() {
        console.log("grid contents", this.contents);
    },
    methods: {
        fetchCardType(data) {
            return this.section.displayType === "LANDSCAPE"
            ? {
                cardName: "Slider",
                type: "LANDSCAPE",
                height: "147px",
                width: "261px",
                playButton: { enablePlay: true, width: "35px", height: "35px" }
            }
            : this.section.displayType === "PORTRAIT"
            ? {
                cardName: "Slider",
                type: "PORTRAIT",
                height: "255px",
                width: "170px",
                playButton: { enablePlay: true, width: "35px", height: "35px" }
            }
            : {
                cardName: "Slider",
                type: "SQUARE",
                height: "186px",
                playButton: { enablePlay: true, width: "35px", height: "35px" }
            };
        },
        lightUp(payload) {
            console.log("content selected", payload);
            eventBus.$emit("off");
            eventBus.$emit(`highlight-${payload.id}`);
            this.detailContent = payload.content;
        },
        closeDetailCard() {
            this.detailContent = null;
        }
    },
    components: {
        categoryCard: () => 
            import(
                /* webpackChunkName: "categorycard" */ "@/components/Templates/categoryCard.vue"
            ),
        detailCard: () => 
            import(
                /* webpackChunkName: "detailcard" */ "@/components/Templates/detailCard.vue"
            ),
    }
}
</script>

<style lang='scss' scoped>
.grid{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}
</style>